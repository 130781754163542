export default {
  isReady: (state) => state.isReady,
  quiz: (state) => state.quiz,
  test: (_, getters) => getters.quiz?.test,
  isTestFinished: (_, getters) => getters.test?.is_finished ?? false,
  isTestActive: (state) => state.isTestActive,
  highlights: (state) => state.highlights,
  quizType: (_, getters) => getters.test?.test_type?.toLowerCase() ?? null,
  questionIndex: (state) => state.questionIndex,
  sectionIndex: (state) => state.sectionIndex,
  sections: (_, getters) => getters.quiz?.sections ?? [],
  studyableSections: (_, getters) => getters.sections.filter((section) => section.can_study),
  actualSection: (_, getters) => getters.studyableSections[getters.sectionIndex] ?? null,
  activeSection: (_, getters) => {
    if (getters.actualSection?.parent_id) {
      return getters.sections.find((section) => section.id === getters.actualSection?.parent_id);
    }
    return getters.actualSection;
  },
  filteredQuestions: (state) => state.filteredQuestions ?? [],
  questions: (_, getters) => {
    const { filteredQuestions, quiz } = getters;
    return (filteredQuestions.length ? filteredQuestions : quiz?.questions) ?? [];
  },
  countableQuestions: (_, getters) => getters.questions.filter((question) => question.countable),
  activeQuestionCounterNumber: (_, getters) => {
    const { activeQuestion, countableQuestions } = getters;
    if (!activeQuestion?.countable) {
      return 0;
    }
    return countableQuestions.findIndex((question) => question.id === activeQuestion.id) + 1;
  },
  passages: (_, getters) => getters.quiz?.passages ?? [],
  activeSectionType: (_, getters) => getters.activeSection?.type,
  activeQuestion: (_, getters) => {
    if (getters.isTestActive) {
      return getters.questions[getters.questionIndex];
    }
    return null;
  },
  answeredCorrectlyPercentage: (_, getters) => {
    const { activeQuestion } = getters;
    if (activeQuestion) {
      const { correct_answer: correctAnswer, statistics } = activeQuestion;
      return statistics[correctAnswer]?.percentage;
    }
  },
  activePassageIndex: (_, getters) => {
    if (getters.isTestActive) {
      return getters.passages.findIndex((passage) => passage.id === getters.activeQuestion?.passage_id) ?? -1;
    }
    return -1;
  },
  activePassage: (_, getters) => {
    return getters.passages[getters.activePassageIndex] ?? null;
  },
  hasNextQuestion: (_, getters) => getters.questionIndex + 1 < getters.questions.length,
  hasPreviousQuestion: (_, getters) => getters.questionIndex > 0,
  hasPreviousServerBaseQuestion: (_, getters) => {
    return getters.questionIndex > 0 && getters.questions[getters.questionIndex - 1].received_from_server;
  },
  hasNextSection: (_, getters) => getters.sectionIndex + 1 < getters.studyableSections.length,
  questionFeatures: (state) => state.questionFeatures,
  sectionFeatures: (state) => state.sectionFeatures,
  allFeatures: (_, getters) => {
    if (getters.isTestActive) {
      return [...getters.sectionFeatures, ...getters.questionFeatures];
    }
    return [];
  },
  timeOnSection(_, getters) {
    return (
      getters.questions?.reduce((accumulator, current) => {
        return accumulator + current.time_on_question;
      }, 0) ?? 0
    );
  },
  remainingTimeOfSection: (_, getters) => {
    const { metadata, activeSection, actualSection, isTestFinished, extendedTimeRatio } = getters;
    if (isTestFinished) {
      return 0;
    }
    if (extendedTimeRatio === Infinity) {
      return Infinity;
    }
    const sectionTime =
      (metadata?.sections ?? {})[activeSection?.section_id]?.duration_in_secs ||
      actualSection?.time_allotted ||
      activeSection?.time_allotted;
    return sectionTime * extendedTimeRatio - getters.timeOnSection;
  },
  isTimeInfinity: (_, getters) => {
    const { extendedTimeRatio } = getters;
    return extendedTimeRatio === Infinity;
  },
  isReviewMode: (state) => state.isReviewMode,
  finishingMode: (state) => state.finishingMode,
  metadata: (_, getters) => getters.test?.metadata ?? {},
  hasNestedSection: (_, getters) => !!getters.actualSection?.parent_id,
  nestedSectionTitle: (_, getters) => {
    const { hasNestedSection, actualSection, activeSection } = getters;
    if (hasNestedSection) {
      return `${activeSection?.title} - ${actualSection?.title}`;
    }
    return activeSection?.title;
  },
  allActiveSubsections: (_, getters) => {
    const { sections, activeSection } = getters;
    return sections.filter((section) => section.parent_id === activeSection?.id);
  },
  indexOfActiveSubsection: (_, getters) => {
    const { allActiveSubsections, actualSection } = getters;
    return allActiveSubsections.findIndex((section) => section.id === actualSection?.id);
  },
  delayLoading: (state) => state.delayLoading,
  extendedTimeRatio(_, getters) {
    const { test, isTestFinished } = getters;
    if (isTestFinished) {
      return 1;
    }
    if (!test?.with_time) {
      return Infinity;
    }
    const times = {
      with_time_1: test?.with_time_1,
      with_time_1_25: test?.with_time_1_25,
      with_time_1_5: test?.with_time_1_5,
      with_time_2: test?.with_time_2,
    };
    const activeTime = Object.keys(times).find((key) => times[key]);
    const activeTimeRatioString = activeTime.match(/with_time_(.*)/)[1];
    return Number(activeTimeRatioString.replace('_', '.'));
  },
  activePassageQuestions: (_, getters) =>
    getters.questions.filter((item) => item.passage_id === getters.activePassage?.id) ?? [],
  answeredQuestionsNumbers: (_, getters) => getters.questions.filter((question) => question.isAnswered).length,
  activeRange: (state) => state.activeRange,
  showSolution: (state) => state.showSolution,
  withSolution: (_, getters) => getters.quiz?.test?.with_solution,
};
