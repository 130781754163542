import { randomFixedInteger } from '~/core/utils/numbers';

export const questionType = {
  MULTIPLE_OPTION: 'multiple_options',
  SINGLE_INPUT: 'single_input',
};

export default class QuestionEntity {
  constructor(json) {
    this.id = json.id ?? randomFixedInteger(10);
    this.daily_id = json.daily_id ?? null;
    this.passage_id = json.passage_id ?? null;
    this.section_id = json.section_id ?? null;
    this.question = json.question ?? '';
    this.solution = json.solution ?? '';
    this.options = json.options ?? [];
    this.options_type = json.options_type ?? questionType.MULTIPLE_OPTION;
    this.correct_answer = json.correct_answer ?? null;
    this.correct_answer_content = json.correct_answer_content ?? null;
    this.link = json.link ?? '';
    this.statistics = json.statistics ?? {};
    this.difficulty = json.difficulty ?? '';
    this.selected_answer = json.selected_answer ?? null;
    this.answer_string = json.answer_string ?? null;
    this.marked = json.marked ?? false;
    this.previous_answer = json.previous_answer ?? null;
    this.time_on_question = json.time_on_question ?? 0;
    this.tags = json.tags ?? [];
    this.button_label = json.button_label ?? '';
    this.button_link = json.button_link ?? '';

    /** Not Used Keys
     * this.answer_a = json.answer_a ?? null;
     * this.answer_a_content = json.answer_a_content ?? null;
     * this.answer_b = json.answer_b ?? null;
     * this.answer_b_content = json.answer_b_content ?? null;
     * this.answer_c = json.answer_c ?? null;
     * this.answer_c_content = json.answer_c_content ?? null;
     * this.answer_d = json.answer_d ?? null;
     * this.answer_d_content = json.answer_d_content ?? null;
     * this.total_answers = json.total_answers ?? 0;
     * this.saved = json.saved ?? false;
     * this.fix_answer = json.fix_answer ?? null;
     */

    /** Custom Properties */
    this.seen = !!this.selected_answer;
    this.component = json.component ?? null;
    this.countable = json.countable ?? true;
    this.navigable = json.navigable ?? true;
    this.received_from_server = json.received_from_server ?? false;
    this.crossedOuts = [];
  }

  get isMultipleChoice() {
    return this.options_type === questionType.MULTIPLE_OPTION;
  }

  get answer_key() {
    if (this.isMultipleChoice) {
      return 'selected_answer';
    }
    return 'answer_string';
  }

  get isAnswered() {
    return !!this[this.answer_key];
  }
}
